const routes = [{
	path: '/resource/template',
	name: 'template',
	meta: {
		title: '资源管理'
	},
    component: () => import('@/views/resource/template.vue'),
},
 ]

export default routes