import Vue from 'vue'
import VueRouter from 'vue-router'
import home from '@/router/home'
import system from '@/router/system'
import information from '@/router/information'
import business from '@/router/business'
import employee from '@/router/employee'
import report from '@/router/report'
import resource from '@/router/resource'
import login from '@/router/login'


Vue.use(VueRouter)
//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
	...home,
	...system,
	...information,
	...business,
	...employee,
	...report,
	...resource,
	...login,
	// 404永远放在最后面，否则所有页面都会404
	{
		path: '/*',
		name: '404',
		meta: {
			title: '404'
		},
		component: () => import('@/views/404.vue'),
	},
]
const router = new VueRouter({
	mode: 'history',
	routes,
})

export default router