<template>
	<div class="dashboard-content">
		<div class="dashboard-left">
			<div id="dashboard-client"></div>
			<div id="dashboard-revenue"></div>
		</div>
		<div class="dashboard-right">
			<div id="dashboard-order"></div>
		</div>
	</div>
</template>

<script>
	import * as echarts from 'echarts';
	import {
		getClientNum,
		getOrderNum,
		getOrderRevenue,
	} from '@/http/dashboard'

	export default {
		data() {
			return {
				chart: '',
			}
		},
		methods: {
			initClient() {
				getClientNum().then(res => {
					if (res.code == 200) {
						let keys = [];
						let vals = [];
						let list = res.data;
						for (let i = 0; i < list.length; i++) {
							keys.push(list[i].yearMonth);
							vals.push(list[i].num);
						}

						let myChart = echarts.init(document.getElementById('dashboard-client'));
						myChart.setOption({
							color:['#08a7d3'],
							title: {
								text: '客戶數量',
								left: 'center',
								subtext: '單位: 個'
							},
							xAxis: {
								data: keys
							},
							yAxis: {},
							series: [{
								type: 'bar',
								data: vals,
								label: {
									show: true,
									position: 'top'
								}
							}]
						});
					}
				})
			},
			initOrder() {
				getOrderNum().then(res => {
					if (res.code == 200) {
						let keys = [];
						let vals = [];
						let list = res.data;
						for (let i = 0; i < list.length; i++) {
							keys.push(list[i].yearMonth);
							vals.push(list[i].num);
						}

						let myChart = echarts.init(document.getElementById('dashboard-revenue'));
						myChart.setOption({
							color:['#08a7d3'],
							title: {
								text: '訂單數量',
								left: 'center',
								subtext: '單位：個'
							},
							xAxis: {
								data: keys
							},
							yAxis: {},
							series: [{
								type: 'bar',
								data: vals,
								label: {
									show: true,
									position: 'top'
								}
							}]
						});
					}
				});
			},
			initRevenue() {
				getOrderRevenue().then(res => {
					if (res.code == 200) {
						let keys = [];
						let vals = [];
						let list = res.data;
						for (let i = 0; i < list.length; i++) {
							keys.push(list[i].yearMonth);
							vals.push(Number(list[i].money).toFixed(2));
						}

						let myChart = echarts.init(document.getElementById('dashboard-order'));
						myChart.setOption({
							color:['#08a7d3'],
							title: {
								text: '訂單收入',
								left: 'center',
								subtext: 'Order Revenue'
							},
							xAxis: {
								type: 'category',
								data: keys
							},
							yAxis: {
								type: 'value'
							},
							series: [{
								data: vals,
								type: 'line',
								label: {
									show: true,
									position: 'top'
								}
							}]
						});
					}
				});
			}
		},
		created() {
			let token = localStorage.getItem('token');
			if (!token) {
				this.$router.push('/login')
			}
			this.initClient();
			this.initOrder();
			this.initRevenue();
		},
	};
</script>

<style lang="less" scoped>
	.dashboard-content {
		width: 100%;
		height: 100%;
		display: flex;

		.dashboard-left {
			width: 50%;
			height: 100%;

			#dashboard-client {
				width: 100%;
				height: 50%;
				border: 1px solid rgba(0, 0, 0, 0.1);
				border-bottom: none;
				border-right: none;
				box-sizing: border-box;
			}

			#dashboard-revenue {
				width: 100%;
				height: 50%;
				border: 1px solid rgba(0, 0, 0, 0.1);
				border-right: none;
				box-sizing: border-box;
			}
		}

		.dashboard-right {
			width: 50%;
			height: 100%;

			#dashboard-order {
				width: 100%;
				height: 100%;
				border: 1px solid rgba(0, 0, 0, 0.1);
				box-sizing: border-box;
			}
		}
	}
</style>